import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import { API_BASE_URL } from '../config';

const AuthService = {
  setTokens(accessToken, refreshToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  },

  getAccessToken() {
    return localStorage.getItem('accessToken');
  },

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  },

  isTokenExpired(token) {
    try {
      const decoded = jwtDecode(token);
      return decoded.exp < Date.now() / 1000;
    } catch (e) {
      return true;
    }
  },

  async refreshToken() {
    try {
      const refreshToken = this.getRefreshToken();
      console.log('Refresh token:', refreshToken); // Add this line
      if (!refreshToken) {
        throw new Error('No refresh token found');
      }
      const response = await axios.post(`${API_BASE_URL}/refresh`, { refreshToken });
      this.setTokens(response.data.accessToken, response.data.refreshToken);
      return response.data.accessToken;
    } catch (error) {
      console.error('Error in refreshToken:', error);
      if (error.response && error.response.status === 401) {
        console.log('Refreshing token failed. Logging out.');
        await this.logout();
        // Navigate to the auth page
        window.location.href = '/auth';
      }
      throw error; // Re-throw the error for the calling function to handle
    }
  },

  async getValidToken() {
    let token = this.getAccessToken();
    if (this.isTokenExpired(token)) {
      try {
        token = await this.refreshToken();
      } catch (error) {
        // If refreshToken throws an error, it means the refresh failed and logout was initiated
        // We can return null or throw an error here, depending on how you want to handle it in your API calls
        return null;
      }
    }
    return token;
  },

  async getAuthHeader() {
    const token = await this.getValidToken();
    return { Authorization: `Bearer ${token}` };
  },

  logout: () => {
    return new Promise((resolve, reject) => {
      try {
        // Clear access token
        localStorage.removeItem('accessToken');

        // Clear refresh token
        localStorage.removeItem('refreshToken');

        // Clear any other user-related data
        localStorage.removeItem('user');

        // You might want to make an API call to invalidate the token on the server
        // This is optional and depends on your backend implementation
        // For example:
        // await fetch(`${API_BASE_URL}/logout`, {
        //   method: 'POST',
        //   headers: {
        //     'Authorization': `Bearer ${this.getAccessToken()}`
        //   }
        // });

        console.log('Logged out successfully');
        resolve();
      } catch (error) {
        console.error('Logout failed:', error);
        reject(error);
      }
    });
  },

  async getCurrentUser() {
    const token = await this.getValidToken();
    if (!token) {
      throw new Error('No valid token found');
    }

    const response = await fetch(`${API_BASE_URL}/users/me`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user details');
    }

    const data = await response.json();
    console.log('Current user details:', data);
    return data.user;
  },

  deleteAccount: async function() {
    const token = await this.getValidToken();
    if (!token) {
      throw new Error('No valid token found');
    }

    const response = await fetch(`${API_BASE_URL}/account`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (!response.ok) {
      throw new Error('Failed to delete account');
    }
    
    await this.logout(); // Clear local storage/tokens after deletion
  },

  async getAccount() {
    const token = await this.getValidToken();
    if (!token) {
      throw new Error('No valid token found');
    }

    const response = await fetch(`${API_BASE_URL}/account`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch account data');
    }
    const data = await response.json();
    return data.account;
  }
};

export default AuthService;
