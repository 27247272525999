import React from 'react';
import './StaticFooter.css';

const StaticFooter = () => {
    return (
        <div className="wb_element wb-layout-element static-footer" data-plugin="LayoutElement">
            <div className="wb_content wb-layout-horizontal">
                <div className="wb_element wb_text_element" data-plugin="TextArea" style={{ lineHeight: 'normal' }}>
                    <p className="wb-stl-footer">© 2024 <a href="http://scanwise.net" data-popup-processed="true">scanwise.net</a></p>
                </div>
                <div className="wb_element wb_element_picture" data-plugin="Picture" title="">
                    <div className="wb_picture_wrap">
                        <div className="wb-picture-wrapper">
                            <img loading="lazy" alt="" src="gallery_gen/1084489b3aa5213c70918ddd3342f3a4_322x128_0x70_322x249_crop.png?ts=1725636619" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaticFooter;