import React, { useState, useEffect, forwardRef } from 'react';
import { Upload, Button, List, Select, message, Modal, Typography as AntTypography } from 'antd'; // Add Typography from antd
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { API_BASE_URL } from '../config';
import AuthService from '../services/AuthService';
const { Dragger } = Upload;
const { Option } = Select;

const DOCUMENT_TYPES = [
    { value: 1, label: 'Generic Document' },
    { value: 2, label: 'Cap Table' },
    // { value: 3, label: 'Internet Document' },
    { value: 4, label: 'Incorporation Document' },
    { value: 5, label: 'Generic Financial Document' },
    { value: 6, label: 'Profit and Loss Statement' },
    { value: 7, label: 'Income Statement' },
    { value: 8, label: 'Cash Flow Statement' },
    { value: 9, label: 'Budget' },
    { value: 10, label: 'Audited Financials' },
    { value: 11, label: 'Business Plan' },
    { value: 12, label: 'Balance Sheet' },
    { value: 13, label: 'Form 990' },
    // { value: 14, label: 'Additional Names for Scans' },
];

const MAX_DOCUMENTS = 20;

const OrganizationDocuments = forwardRef(({ entity_id }, ref) => {
    const [fileList, setFileList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState(1);
    const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
    const [textModalVisible, setTextModalVisible] = useState(false);
    const [selectedText, setSelectedText] = useState('');

    useEffect(() => {
        fetchDocuments();
    }, [entity_id]);

    const fetchDocuments = async () => {
        try {
            const headers = await AuthService.getAuthHeader();
            const response = await fetch(`${API_BASE_URL}/analysed_entities/${entity_id}/documents`, { headers });
            if (!response.ok) {
                throw new Error('Failed to fetch documents');
            }
            const data = await response.json();
            console.log(data);
            setFileList(data.documents.map(doc => ({
                uid: doc.id,
                name: doc.original_file_name,
                document_type: doc.document_type,
                entity_id: entity_id,
                text_representation: doc.text_representation || '',
            })));
        } catch (error) {
            console.error('Error fetching documents:', error);
            message.error('Failed to load documents');
        }
    };

    const handleUpload = async (file) => {        
        // Check for duplicate filename
        const isDuplicate = fileList.some(existingFile => 
            existingFile.name.toLowerCase() === file.name.toLowerCase()
        );

        if (isDuplicate) {
            setSelectedFile(file);
            setIsDuplicateModalVisible(true);
        } else {
            setSelectedFile(file);
            setIsModalVisible(true);
        }
    };

    const handleDuplicateProceed = () => {
        setIsDuplicateModalVisible(false);
        setIsModalVisible(true);
    };

    const handleDuplicateCancel = () => {
        setIsDuplicateModalVisible(false);
        setSelectedFile(null);
    };

    const handleModalOk = async () => {
        setIsModalVisible(false);
        if (!selectedFile) return;

        try {
            const headers = await AuthService.getAuthHeader();
            const formData = new FormData();
            formData.append(String(selectedDocumentType), selectedFile);

            const response = await fetch(`${API_BASE_URL}/analysed_entities/${entity_id}/documents`, {
                method: 'POST',
                headers: headers,
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Upload failed');
            }

            const data = await response.json();

            const document = data.added_documents[0];

            setFileList(prevList => [...prevList, {
                uid: document.id,
                name: document.original_file_name,
                status: 'done',
                document_type: document.document_type,
                entity_id: entity_id,
            }]);

            message.success(`${selectedFile.name} file uploaded successfully.`);
        } catch (error) {
            console.error('Error uploading file:', error);
            message.error(`${selectedFile.name} file upload failed.`);
        }

        setSelectedFile(null);
        setSelectedDocumentType(1);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        setSelectedFile(null);
        setSelectedDocumentType(1);
    };

    const handleDelete = async (file) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this document?',
            content: `This will permanently delete "${file.name}".`,
            okText: 'Yes, delete',
            okType: 'danger',
            cancelText: 'No, cancel',
            onOk: async () => {
                try {
                    const headers = await AuthService.getAuthHeader();
                    const response = await fetch(`${API_BASE_URL}/documents/${file.uid}`, {
                        method: 'DELETE',
                        headers: headers,
                    });

                    if (!response.ok) {
                        throw new Error('Failed to delete document');
                    }

                    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
                    setFileList(updatedFileList);
                    message.success(`${file.name} deleted successfully.`);
                } catch (error) {
                    console.error('Error deleting document:', error);
                    message.error(`Failed to delete ${file.name}`);
                }
            },
        });
    };

    const handleDocumentTypeChange = async (value, file) => {
        try {
            const headers = await AuthService.getAuthHeader();
            const response = await fetch(`${API_BASE_URL}/documents/${file.uid}?document_type=${value}`, {
                method: 'PUT',
                headers: headers,
            });

            if (!response.ok) {
                throw new Error('Failed to update document type');
            }

            const updatedFileList = fileList.map((item) =>
                item.uid === file.uid ? { ...item, document_type: value } : item
            );
            setFileList(updatedFileList);
            message.success(`Document type updated successfully.`);
        } catch (error) {
            console.error('Error updating document type:', error);
            message.error(`Failed to update document type.`);
        }
    };

    // Sort DOCUMENT_TYPES alphabetically by label
    const sortedDocumentTypes = [...DOCUMENT_TYPES].sort((a, b) =>
        a.label.localeCompare(b.label)
    );

    const handleTextClick = (text) => {
        setSelectedText(text);
        setTextModalVisible(true);
    };

    React.useImperativeHandle(ref, () => ({
        fetchDocuments: () => {
            fetchDocuments();
        }
    }));

    return (
        <div>
            <Typography variant="h5">Documents ({fileList.length})</Typography>
            {fileList.length >= MAX_DOCUMENTS ? (
                <div style={{ 
                    textAlign: 'center', 
                    padding: '20px', 
                    background: '#fafafa', 
                    border: '1px dashed #d9d9d9',
                    borderRadius: '2px'
                }}>
                    Maximum number of documents ({MAX_DOCUMENTS}) reached.
                </div>
            ) : (
                <Dragger
                    multiple={false}
                    showUploadList={false}
                    beforeUpload={(file) => {
                        handleUpload(file);
                        return false;
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single upload. After selecting a file, you will be prompted to select the document type.
                    </p>
                </Dragger>
            )}

            <Modal
                title="Duplicate File Warning"
                visible={isDuplicateModalVisible}
                onOk={handleDuplicateProceed}
                onCancel={handleDuplicateCancel}
                okText="Proceed Anyway"
                cancelText="Cancel Upload"
            >
                <p>A document with the name "{selectedFile?.name}" already exists.</p>
                <p>Do you want to proceed with uploading this file anyway?</p>
            </Modal>

            <Modal
                title="Select Document Type"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <p style={{ marginBottom: '16px' }}>
                    File: <strong>{selectedFile?.name}</strong>
                </p>
                <Select
                    value={selectedDocumentType}
                    style={{ width: '100%' }}
                    onChange={(value) => setSelectedDocumentType(value)}
                >
                    {sortedDocumentTypes.map((type) => (
                        <Option key={type.value} value={type.value}>
                            {type.label}
                        </Option>
                    ))}
                </Select>
            </Modal>

            <Modal
                title="Debug: Extracted Document Text"
                visible={textModalVisible}
                onCancel={() => setTextModalVisible(false)}
                footer={null}
                width={800}
            >
                <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
                    <AntTypography.Paragraph style={{ whiteSpace: 'pre-line' }}>
                        {selectedText}
                    </AntTypography.Paragraph>
                </div>
            </Modal>

            <List
                itemLayout="horizontal"
                dataSource={fileList}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Select
                                value={item.document_type}
                                style={{ width: 250 }}
                                onChange={(value) => handleDocumentTypeChange(value, item)}
                            >
                                {sortedDocumentTypes.map((type) => (
                                    <Option key={type.value} value={type.value}>
                                        {type.label}
                                    </Option>
                                ))}
                            </Select>,
                            <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={() => handleDelete(item)}
                                danger
                            >
                                Delete
                            </Button>,
                        ]}
                    >
                        <List.Item.Meta
                            title={item.name}
                            description={
                                <div>
                                    <div>{`Name: ${item.name} | Type: ${DOCUMENT_TYPES.find(t => t.value === item.document_type)?.label || 'Generic'}`}</div>
                                    {item.text_representation && (
                                        <div
                                            onClick={() => handleTextClick(item.text_representation)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <strong>Debug:</strong> {`${item.text_representation.slice(0, 100)}${item.text_representation.length > 100 ? '...' : ''}`}
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </List.Item>
                )}
            />
        </div>
    );
});

OrganizationDocuments.propTypes = {
    entity_id: PropTypes.string.isRequired,
};

export default OrganizationDocuments;
