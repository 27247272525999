import React, { useState } from 'react';
import styles from './EntityStaffMembers.module.css';
import { Typography, Button, TextField, Box, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EntityStaffMembers = ({ entity_id, staffMembers, setStaffMembers }) => {
    const [newMember, setNewMember] = useState('');

    const handleAddMember = () => {
        if (newMember.trim()) {
            // Remove extra spaces and capitalize each word of the new member's name
            const capitalizedName = newMember
                .trim()
                .replace(/\s+/g, ' ')
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');

            setStaffMembers([...staffMembers, capitalizedName]);
            setNewMember('');
        }
    };

    const handleDeleteMember = (index) => {
        const updatedMembers = staffMembers.filter((_, i) => i !== index);
        setStaffMembers(updatedMembers);
    };

    return (
        <div className={styles.entityStaffMembers}>
            <Typography variant="h5">Staff Members</Typography>
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
                <Box display="flex" alignItems="center" width="70%" maxWidth="500px">
                    <TextField
                        value={newMember}
                        onChange={(e) => setNewMember(e.target.value)}
                        placeholder="Enter new staff member name"
                        variant="outlined"
                        size="small"
                        sx={{ flexGrow: 1, mr: 1 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddMember}
                    >
                        Add Member
                    </Button>
                </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
                {staffMembers.length > 0 ? (
                    staffMembers.map((member, index) => (
                        <Chip
                            key={index}
                            label={member}
                            onDelete={() => handleDeleteMember(index)}
                            deleteIcon={<CloseIcon />}
                            sx={{
                                fontSize: '1rem',
                                padding: '10px',
                                border: '2px solid #2196f3',
                                borderRadius: '8px',
                                '& .MuiChip-label': {
                                    cursor: 'text',
                                    userSelect: 'text',
                                },
                            }}
                        />
                    ))
                ) : (
                    <Box width="100%" textAlign="center">
                        <Typography variant="body1" color="textSecondary">
                            No staff members added
                        </Typography>
                    </Box>
                )}
            </Box>
        </div>
    );
};

export default EntityStaffMembers;
