import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './DashboardMenu.module.css';
import AuthService from '../services/AuthService';
import { toast } from 'react-toastify';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { blue } from '@mui/material/colors';

function DashboardMenu({ setIsLoggedIn, isAdmin, isUserApproved }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [logoutConfirmOpen, setLogoutConfirmOpen] = useState(false);

  const handleLogoutClick = () => {
    setLogoutConfirmOpen(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await AuthService.logout();
      setIsLoggedIn(false);
      setLogoutConfirmOpen(false);
      toast.success('Logged out successfully');
      navigate('/auth');
    } catch (error) {
      console.error('Logout failed:', error);
      toast.error('Logout failed. Please try again.');
    }
  };

  const handleLogoutCancel = () => {
    setLogoutConfirmOpen(false);
  };

  return (
    <nav className={styles.dashboardMenu}>
      <ul className={styles.menuList}>
        <li className={`${styles.menuItem} ${location.pathname === '/' ? styles.active : ''}`}>
          <Link to="/" className={styles.menuLink}>Home</Link>
        </li>
        {isUserApproved && (
          <>
            <li className={`${styles.menuItem} ${location.pathname === '/entities' ||
              location.pathname.startsWith('/entity/') ||
              location.pathname === '/entity_new'
              ? styles.active
              : ''
              }`}>
              <Link to="/entities" className={styles.menuLink}>Organizations</Link>
            </li>
            <li className={`${styles.menuItem} ${location.pathname === '/payments' ? styles.active : ''}`}>
              <Link to="/payments" className={styles.menuLink}>Payments</Link>
            </li>
          </>
        )}
        <li className={`${styles.menuItem} ${location.pathname === '/account' ? styles.active : ''}`}>
          <Link to="/account" className={styles.menuLink}>Account</Link>
        </li>
        {isAdmin && (
          <li className={`${styles.menuItem} ${location.pathname === '/admin' ? styles.active : ''}`}>
            <Link to="/admin" className={styles.menuLink}>Admin</Link>
          </li>
        )}
        {/* Add more menu items as needed */}
      </ul>
      <button className={`${styles.menuItem} ${styles.logoutButton}`} onClick={handleLogoutClick}>
        <i className={styles.logoutIcon}></i>
        Log Out
      </button>
      <Dialog
        open={logoutConfirmOpen}
        onClose={handleLogoutCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderTop: `4px solid ${blue[500]}` },
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ color: blue[500] }}>{"Confirm Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel}>Cancel</Button>
          <Button onClick={handleLogoutConfirm} style={{ color: blue[500] }} autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </nav>
  );
}

export default DashboardMenu;
