import React from 'react';
import styles from './Home.module.css';
import ListOfPrompts from './ListOfPrompts';
import UserRegistrationRequests from './UserRegistrationRequests';

function AdminRoot({ setIsLoggedIn }) {
    return (
        <div>
            <h1 className={styles.title}>Admin Dashboard</h1>

            <section>
                <h2>Website Statistics</h2>
                <p>TBD - Website statistics component will be placed here</p>
                {/* <WebsiteStatistics /> */}
            </section>

            <section>
                <h2>Prompts Customization</h2>
                <ListOfPrompts />
            </section>

            <section>
                <h2>Pending User Registration Requests</h2>
                <p>TBD - Component for approving new user registrations will be placed here</p>
                <UserRegistrationRequests />
            </section>

            <section>
                <h2>Invites Creation</h2>
                <p>TBD - Component for generating and managing invites will be placed here</p>
                {/* <InvitesManagement /> */}
            </section>

            <section>
                <h2>Recent Website Errors</h2>
                <p>TBD - Component for reviewing recent website errors will be placed here</p>
                {/* <ErrorReview /> */}
            </section>
        </div>
    );
}

export default AdminRoot;

