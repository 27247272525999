import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { List, ListItem, ListItemText, Collapse, Typography, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AuthService from '../services/AuthService';
import EntityReport from './EntityReport';
import { useInterval } from '../hooks/useInterval'; // You'll need to create this custom hook

import { API_BASE_URL } from '../config';

const EntityReports = ({ analysedEntityId, onReportComplete, skipSettings }) => {
    const [reports, setReports] = useState([]);
    const [latestReport, setLatestReport] = useState(null);
    const [open, setOpen] = useState(false);
    const [hasInProgressReport, setHasInProgressReport] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [reportToDelete, setReportToDelete] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    useEffect(() => {
        fetchReports();
    }, [analysedEntityId]);

    useInterval(() => {
        if (hasInProgressReport) {
            fetchReports();
        }
    }, 500); // Re-fetch every 0.5 seconds if there's an in-progress report

    useInterval(() => {
        if (reports.length > 0) {
            fetchReports();
        }
    }, 60000); // Re-fetch every 60 seconds (1 minute) if there's at least one report

    const fetchReports = async () => {
        try {
            const headers = await AuthService.getAuthHeader();
            const response = await axios.get(`${API_BASE_URL}/analysed_entities/${analysedEntityId}/reports`, { headers });
            setReports(response.data.reports);
            let latestReportCurrent = response.data.reports[0];

            // Check if the latest report status has changed
            if (latestReport && latestReportCurrent && latestReport.id === latestReportCurrent.id) {
                if (latestReport.generation_completed === false && latestReportCurrent.generation_completed === true) {
                    console.log('Report generated successfully!', latestReportCurrent.generation_status_string);
                    setAlertMessage('Report generated successfully! ' + latestReportCurrent.generation_status_string);
                    setAlertSeverity('success');
                    setAlertOpen(true);
                    onReportComplete?.(); // Call the callback when report completes successfully
                } else if (latestReport.generation_failed === false && latestReportCurrent.generation_failed === true) {
                    console.log('Report generation failed.');
                    setAlertMessage('Report generation failed. ' + latestReportCurrent.generation_status_string + ' ' + latestReportCurrent.document_causing_error_description + ' ' + latestReportCurrent.document_causing_error_recovery_suggestion);
                    setAlertSeverity('error');
                    setAlertOpen(true);
                    onReportComplete?.(); // Call the callback when report fails
                }
            }

            setLatestReport(latestReportCurrent);

            // Check if latest report is still in progress or reports is expanded and any is in progress.
            const inProgressReport = (latestReportCurrent && latestReportCurrent.generation_completed === false && latestReportCurrent.generation_failed === false) ||
                (open && response.data.reports.some(report => report.generation_completed === false && report.generation_failed === false));
            setHasInProgressReport(!!inProgressReport);
        } catch (error) {
            console.error('Error fetching reports:', error);
        }
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleGenerateReport = async () => {
        try {
            const headers = await AuthService.getAuthHeader();
            const queryParams = new URLSearchParams({
                skip_adverse_media_scan: skipSettings?.skipAdverseMediaScan || false,
                skip_sanctions_check: skipSettings?.skipSanctionsCheck || false
            }).toString();

            const response = await axios.post(
                `${API_BASE_URL}/analysed_entities/${analysedEntityId}/reports?${queryParams}`,
                {},
                { headers }
            );
            console.log('Report generated:', response.data);
            fetchReports();
        } catch (error) {
            console.error('Error generating report:', error);
        }
    };

    const handleReportDeleted = (deletedReportId) => {
        setReports(prevReports => prevReports.filter(report => report.id !== deletedReportId));
        if (latestReport && latestReport.id === deletedReportId) {
            setLatestReport(reports[1] || null);
        }
    };

    const handleDeleteConfirm = (report) => {
        setReportToDelete(report);
        setDeleteConfirmOpen(true);
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmOpen(false);
        setReportToDelete(null);
    };

    const handleDeleteConfirmed = async () => {
        if (reportToDelete) {
            try {
                const headers = await AuthService.getAuthHeader();
                await axios.delete(`${API_BASE_URL}/reports/${reportToDelete.id}`, { headers });
                handleReportDeleted(reportToDelete.id);
            } catch (error) {
                console.error('Error deleting report:', error);
            }
        }
        setDeleteConfirmOpen(false);
        setReportToDelete(null);
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    if (reports.length === 0) {
        return (
            <div>
                <Typography variant="h5">Reports</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem', marginBottom: '1rem' }}>No reports</Typography>
                <Button variant="contained" color="primary" onClick={handleGenerateReport}>
                    Generate Report
                </Button>
            </div>
        );
    }

    return (
        <div>
            <Typography variant="h5">Reports</Typography>

            {latestReport && (
                <Paper elevation={3} style={{ padding: '1rem', marginBottom: '1rem' }}>
                    <Typography variant="h6">Latest Report</Typography>
                    <EntityReport
                        key={0}
                        report={latestReport}
                        onReportDeleted={handleReportDeleted}
                        onDeleteConfirm={handleDeleteConfirm}
                    />
                </Paper>
            )}

            {reports.length > 1 && (
                <List>
                    <ListItem button onClick={handleToggle}>
                        <ListItemText primary={`Previous Reports (${reports.length - 1})`} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {reports.slice(1).map((report, index) => (
                                <EntityReport
                                    key={index}
                                    report={report}
                                    onReportDeleted={handleReportDeleted}
                                    onDeleteConfirm={handleDeleteConfirm}
                                />
                            ))}
                        </List>
                    </Collapse>
                </List>
            )}

            <Button variant="contained" color="primary" onClick={handleGenerateReport} style={{ marginTop: '1rem' }}>
                Generate Report
            </Button>

            <Dialog
                open={deleteConfirmOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this report? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={alertOpen}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {alertSeverity === 'success' ? "Report Generation Successful" : "Report Generation Failed"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {alertMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

EntityReports.propTypes = {
    analysedEntityId: PropTypes.string.isRequired,
    onReportComplete: PropTypes.func,
    skipSettings: PropTypes.shape({
        skipAdverseMediaScan: PropTypes.bool,
        skipSanctionsCheck: PropTypes.bool
    })
};

export default EntityReports;
