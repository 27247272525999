import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Home.module.css';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import stylesListOfEntities from './ListOfEntities.module.css';

import { API_BASE_URL } from '../config';

const ListOfEntities = ({ setIsLoggedIn }) => {
    const [entities, setEntities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchEntities();
    }, []);

    const fetchEntities = async () => {
        try {
            const headers = await AuthService.getAuthHeader();
            const response = await axios.get(`${API_BASE_URL}/analysed_entities`, { headers });
            setEntities(response.data.analysed_entities);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch entities');
            setLoading(false);
        }
    };

    const handleAddNewOrganization = () => {
        navigate('/entity_new');
    };

    const handleEntityClick = (entityId) => {
        navigate(`/entity/${entityId}`);
    };

    return (
        <div>
            <h2>List of Entities</h2>
            {loading && <div>Loading...</div>}
            {error && <div>{error}</div>}
            {!loading && !error && entities.length === 0 && (
                <div className={styles.noEntitiesMessage}>No entities found</div>
            )}
            {entities.length > 0 && (
                <table className={`${styles.entitiesTable} ${stylesListOfEntities.table}`}>
                    <thead>
                        <tr>
                            <th className={stylesListOfEntities.th}>Entity Name</th>
                            <th className={stylesListOfEntities.th}>Documents Count</th>
                            <th className={stylesListOfEntities.th}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {entities.map((entity) => (
                            <tr
                                key={entity.id}
                                onClick={() => handleEntityClick(entity.id)}
                                className={`${stylesListOfEntities.clickableRow} ${stylesListOfEntities.tr}`}
                            >
                                <td className={stylesListOfEntities.td}>{entity.company_name}</td>
                                <td className={stylesListOfEntities.td}>{entity.documents_count}</td>
                                <td className={stylesListOfEntities.td}>{entity.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <button className={styles.addButton} onClick={handleAddNewOrganization}>
                Add New Organization
            </button>
        </div>
    );
};

export default ListOfEntities;
