import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AuthService from '../services/AuthService';
import { API_BASE_URL } from '../config';
import styles from './ListOfPrompts.module.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const ListOfPrompts = () => {
    const [prompts, setPrompts] = useState([]);
    const [editingPrompt, setEditingPrompt] = useState(null);
    const [editedPrompt, setEditedPrompt] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        fetchPrompts();
    }, []);

    const fetchPrompts = async () => {
        try {
            const headers = await AuthService.getAuthHeader();
            const response = await axios.get(`${API_BASE_URL}/prompts`, { headers });
            setPrompts(response.data.prompts);
        } catch (error) {
            console.error('Error fetching prompts:', error);
        }
    };

    const handleEdit = (prompt) => {
        console.log('Editing prompt:', prompt);
        setEditingPrompt(prompt);
        setEditedPrompt(prompt.prompt);
        setIsDialogOpen(true);
    };

    const handleUpdate = async () => {
        try {
            const headers = await AuthService.getAuthHeader();
            await axios.put(`${API_BASE_URL}/prompts/${editingPrompt.id}`, { prompt: editedPrompt }, { headers });
            setSnackbar({ open: true, message: 'Prompt updated successfully', severity: 'success' });
            setIsDialogOpen(false);
            setEditingPrompt(null);
            setEditedPrompt('');
            fetchPrompts(); // Refresh the list after update
        } catch (error) {
            console.error('Error updating prompt:', error);
            setSnackbar({ open: true, message: 'Failed to update prompt', severity: 'error' });
        }
    };

    const handleCancel = () => {
        setIsDialogOpen(false);
        setEditingPrompt(null);
        setEditedPrompt('');
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };


    return (
        <div>
            <h2>List of Prompts</h2>
            {prompts.length === 0 ? (
                <p>No prompts found</p>
            ) : (
                <ul className={styles.promptList}>
                    {prompts.map((prompt) => (
                        <li key={prompt.id} className={styles.promptItem}>
                            <div className={styles.promptContent}>
                                <strong className={styles.promptName}>{prompt.name}</strong>
                                <p className={styles.promptText}>{prompt.prompt}</p>
                            </div>
                            <Button 
                                variant="outlined" 
                                onClick={() => handleEdit(prompt)}
                                className={styles.editButton}
                            >
                                Edit
                            </Button>
                        </li>
                    ))}
                </ul>
            )}

            <Dialog
                open={isDialogOpen}
                onClose={handleCancel}
                aria-labelledby="edit-prompt-dialog-title"
                aria-describedby="edit-prompt-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="edit-prompt-dialog-title">Edit Prompt</DialogTitle>
                <DialogContent>
                    <TextField
                        multiline
                        rows={10}
                        fullWidth
                        variant="outlined"
                        value={editedPrompt}
                        onChange={(e) => setEditedPrompt(e.target.value)}
                        InputProps={{
                            style: { fontSize: '1.1rem' }
                        }}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUpdate}
                        color="primary"
                        disabled={editedPrompt === editingPrompt?.prompt}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default ListOfPrompts;
