import React from 'react';
import './StaticHeader.css';

const baseUrl = "https://scanwise.net";

const StaticHeader = () => {
    return (
        <div className="static-header">
            <div id="wb_header_a188dd9ca654008699d0fd7836a7e187" className="wb_element wb-sticky wb-layout-element" data-plugin="LayoutElement" data-h-align="center" data-v-align="top">
                <div className="wb_content wb-layout-vertical">
                    <div id="a188dd9c96ed029b80196818d7ddd771" className="wb_element wb-layout-element" data-plugin="LayoutElement">
                        <div className="wb_content wb-layout-vertical">
                            <div id="a191ab4daf7c00c733b9d68b5cf2f302" className="wb_element wb-layout-element" data-plugin="LayoutElement">
                                <div className="wb_content wb-layout-horizontal">
                                    <div id="a188dd9c96ed04da11beb872f676c2f2" className="wb_element wb_element_picture" data-plugin="Picture" title="">
                                        <div className="wb_picture_wrap">
                                            <div className="wb-picture-wrapper">
                                                <img loading="lazy" alt="" src="gallery_gen/7f6497be883122666a59da1f9e5830e8_192x129_13x16_222x171_crop.png?ts=1725636619" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="a188dd9c96ed05396450aaf4e995a125" className="wb_element wb_text_element" data-plugin="TextArea" style={{ lineHeight: 'normal' }}>
                                        <h4 className="wb-stl-pagetitle">SCANWISE</h4>
                                        <p className="wb-stl-custom4">
                                            <a href={`${baseUrl}`} data-popup-processed="true">
                                                <span style={{ color: 'rgba(255,255,255,1)' }}>Online streamlined due diligence solutions</span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div id="a188dd9c96ed060762bdbcb750fd43a5" className="wb_element wb-menu wb-prevent-layout-click wb-menu-mobile" data-plugin="Menu">
                                <a className="btn btn-default btn-collapser" data-popup-processed="true">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </a>
                                <ul className="hmenu" dir="ltr" style={{ top: '181px', maxHeight: 'calc(-181px + 98vh)' }}>
                                    <li data-anchor="home" className="wb_this_page_menu_item wb-anim wb-anim-fade-in-right active">
                                        <a href={`${baseUrl}#home`} data-popup-processed="true">Home</a>
                                    </li>
                                    <li data-anchor="About-us" className="wb_this_page_menu_item wb-anim wb-anim-fade-in-right">
                                        <a href={`${baseUrl}#About-us`} data-popup-processed="true">About us</a>
                                    </li>
                                    <li data-anchor="preferences" className="wb_this_page_menu_item wb-anim wb-anim-fade-in-right">
                                        <a href={`${baseUrl}#preferences`} data-popup-processed="true">Our Solutions</a>
                                    </li>
                                    <li data-anchor="Pricing" className="wb_this_page_menu_item wb-anim wb-anim-fade-in-right">
                                        <a href={`${baseUrl}#Pricing`} data-popup-processed="true">Pricing</a>
                                    </li>
                                    <li data-anchor="Resources" className="wb_this_page_menu_item wb-anim wb-anim-fade-in-right">
                                        <a href={`${baseUrl}#Resources`} data-popup-processed="true">Resources</a>
                                    </li>
                                    <li data-anchor="contacts" className="wb_this_page_menu_item wb-anim wb-anim-fade-in-right">
                                        <a href={`${baseUrl}#contacts`} data-popup-processed="true">Contacts</a>
                                    </li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaticHeader;
