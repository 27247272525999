import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import entityStyles from './EntityDetails.module.css';
import axios from 'axios';
import AuthService from '../services/AuthService';
import { useNavigate } from 'react-router-dom';
import OrganizationDocuments from './OrganizationDocuments';
import EntityReports from './EntityReports';
import EntityStaffMembers from './EntityStaffMembers';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, FormControlLabel, Checkbox } from '@mui/material';
import Cookies from 'js-cookie';

import { API_BASE_URL } from '../config';

const EntityDetails = ({ setIsLoggedIn }) => {
    const { id } = useParams();
    const [isNewEntity, setIsNewEntity] = useState(true);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        company_name: '',
        description: '',
    });

    const [files, setFiles] = useState({
        image: null,
        document: null,
    });

    const [analysedEntityData, setAnalysedEntityData] = useState(null);

    const [initialFormData, setInitialFormData] = useState({
        company_name: '',
        description: '',
    });

    const [staffMembers, setStaffMembers] = useState([]);

    const [showNotification, setShowNotification] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);
    const orgDocumentsRef = useRef(null);

    const [scanSettings, setScanSettings] = useState({
        skipAdverseMediaScan: Cookies.get('skipAdverseMediaScan') === 'true',
        skipSanctionsCheck: Cookies.get('skipSanctionsCheck') === 'true'
    });

    useEffect(() => {
        setIsNewEntity(!id);
        if (id) {
            fetchEntityData(id);
            checkAdminStatus();
        }
    }, [id]);

    const fetchEntityData = async (entityId) => {
        try {
            const headers = await AuthService.getAuthHeader();
            const response = await axios.get(`${API_BASE_URL}/analysed_entities/${entityId}`, { headers });
            const entityData = response.data.analysed_entity;
            setAnalysedEntityData(entityData);
            setFormData({
                company_name: entityData.company_name || '',
                description: entityData.description || '',
            });
            setInitialFormData({
                company_name: entityData.company_name || '',
                description: entityData.description || '',
            });
            // Parse and set staff members, ensuring no empty string in the array
            setStaffMembers(
                entityData.additional_names_for_scans_comma_separated
                    ? entityData.additional_names_for_scans_comma_separated.split(',').map(name => name.trim()).filter(Boolean)
                    : []
            );
        } catch (error) {
            console.error('Error fetching entity data:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const headers = await AuthService.getAuthHeader();
            let response;

            const dataToSend = {
                ...formData,
                additional_names_for_scans_comma_separated: staffMembers.join(', ')
            };

            if (isNewEntity) {
                response = await axios.post(`${API_BASE_URL}/analysed_entities`, dataToSend, { headers });
            } else {
                response = await axios.put(`${API_BASE_URL}/analysed_entities/${id}`, dataToSend, { headers });
            }

            let analysed_entity_data;
            if (isNewEntity) {
                analysed_entity_data = response.data.analysed_entity
            } else {
                analysed_entity_data = response.data.updated;
            }
            setAnalysedEntityData(analysed_entity_data);

            // Reset initialFormData to current form content
            setInitialFormData({ ...formData });

            // Show notification
            setShowNotification(true);
            // Hide notification after 3 seconds
            setTimeout(() => setShowNotification(false), 3000);

            if (isNewEntity) {
                // Navigate to the newly created entity's page
                navigate(`/entity/${analysed_entity_data.id}`);
            }
        } catch (error) {
            console.error('Error saving entity:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    const handleDeleteClick = () => {
        setDeleteConfirmOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const headers = await AuthService.getAuthHeader();
            await axios.delete(`${API_BASE_URL}/analysed_entities/${id}`, { headers });
            setDeleteConfirmOpen(false);
            navigate('/entities');
        } catch (error) {
            console.error('Error deleting entity:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmOpen(false);
    };

    const isFormChanged = () => {
        return formData.company_name !== initialFormData.company_name ||
            formData.description !== initialFormData.description ||
            staffMembers.join(',') !== (analysedEntityData?.additional_names_for_scans_comma_separated || '');
    };

    const isCreateButtonEnabled = () => {
        return formData.company_name.trim() !== '';
    };

    const checkAdminStatus = async () => {
        try {
            const user = await AuthService.getCurrentUser();
            setIsAdmin(user?.is_admin || false);
        } catch (error) {
            console.error('Error checking admin status:', error);
        }
    };

    const handleDocumentsRefresh = () => {
        console.log("onReportComplete called", isAdmin, orgDocumentsRef.current);
        if (isAdmin && orgDocumentsRef.current) {
            console.log("will refresh documents.");
            orgDocumentsRef.current.fetchDocuments();
        }
    };

    const handleScanSettingChange = (event) => {
        const { name, checked } = event.target;
        setScanSettings(prev => {
            const newSettings = {
                ...prev,
                [name]: checked
            };
            // Save to cookies
            Cookies.set(name, checked);
            return newSettings;
        });
    };

    const renderForm = () => {
        if (isNewEntity) {
            return (
                <>
                    <div>
                        <label htmlFor="company_name">Organization name: </label>
                        <input
                            type="text"
                            id="company_name"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="description">Description: </label>
                        <input
                            type="text"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                        />
                    </div>
                    <button
                        type="submit"
                        className={`${entityStyles.submitButton} ${!isCreateButtonEnabled() ? entityStyles.disabledButton : ''}`}
                        disabled={!isCreateButtonEnabled()}
                    >
                        Create Organization
                    </button>
                </>
            );
        }

        return (
            <>
                <div>
                    <label htmlFor="company_name">Organization name: </label>
                    <input
                        type="text"
                        id="company_name"
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label htmlFor="description">Description: </label>
                    <input
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                    />
                </div>

                <div className={entityStyles.componentWrapper}>
                    <EntityStaffMembers
                        entity_id={id}
                        staffMembers={staffMembers}
                        setStaffMembers={setStaffMembers}
                    />
                </div>
                <button
                    type="submit"
                    className={`${entityStyles.submitButton} ${!isFormChanged() ? entityStyles.disabledButton : ''}`}
                    disabled={!isFormChanged()}
                >
                    Update Organization
                </button>
                <div className={entityStyles.componentWrapper}>
                    <OrganizationDocuments 
                        entity_id={id} 
                        ref={orgDocumentsRef}
                    />
                </div>
                <div className={entityStyles.componentWrapper}>
                    <Typography variant="h5">Report generation settings</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={scanSettings.skipAdverseMediaScan}
                                onChange={handleScanSettingChange}
                                name="skipAdverseMediaScan"
                            />
                        }
                        label="Skip adverse media scan"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={scanSettings.skipSanctionsCheck}
                                onChange={handleScanSettingChange}
                                name="skipSanctionsCheck"
                            />
                        }
                        label="Skip sanctions check"
                    />
                </div>
                <div className={entityStyles.componentWrapper}>
                    <EntityReports 
                        analysedEntityId={id} 
                        onReportComplete={handleDocumentsRefresh}
                        skipSettings={scanSettings}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            {showNotification && (
                <div className={entityStyles.notification}>
                    Organization successfully updated!
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <h2>{isNewEntity ? 'Create New Organization' : 'Organization'}</h2>

                {renderForm()}

            </form>

            {!isNewEntity && (
                <button
                    onClick={handleDeleteClick}
                    className={`${entityStyles.deleteButton} ${entityStyles.submitButton}`}
                >
                    Delete Organization
                </button>
            )}
            <Dialog
                open={deleteConfirmOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this organization? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EntityDetails;
