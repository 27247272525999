import React from 'react';
import styles from './Home.module.css';

function Payments({ setIsLoggedIn }) {
    return (
        <div>
            <h1 className={styles.title}>Payments</h1>
            <p className={styles.description}>TBD - Under construction</p>
            {/* Add more content for your home page */}
        </div>
    );
}

export default Payments;
