import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AuthService from '../services/AuthService';
import { API_BASE_URL } from '../config';
import styles from './UserRegistrationRequests.module.css';
const UserRegistrationRequests = () => {
    const [pendingUsers, setPendingUsers] = useState([]);

    useEffect(() => {
        fetchPendingUsers();
    }, []);

    const fetchPendingUsers = async () => {
        try {
            const headers = await AuthService.getAuthHeader();
            const response = await axios.get(`${API_BASE_URL}/users`, {
                headers,
                params: { pending_users: true }
            });
            setPendingUsers(response.data.users);
        } catch (error) {
            console.error('Error fetching pending users:', error);
        }
    };

    const approveUser = async (userId) => {
        try {
            const headers = await AuthService.getAuthHeader();
            await axios.post(`${API_BASE_URL}/users/${userId}/approve`, {}, { headers });
            fetchPendingUsers(); // Refresh the list after approval
        } catch (error) {
            console.error('Error approving user:', error);
        }
    };

    return (
        <div className={styles.userRegistrationRequests}>
            <h2>Pending User Registration Requests</h2>
            {pendingUsers.length === 0 ? (
                <p>No pending registration requests.</p>
            ) : (
                <ul className={styles.registrationRequestsList}>
                    {pendingUsers.map((user) => (
                        <li key={user.id} className={styles.registrationRequestItem}>
                            <div className={styles.userInfo}>
                                <span className={styles.userName}>{user.name}</span>
                                <span className={styles.userEmail}>
                                    Email: {user.email} Id: {user.id}
                                    {user?.email_verified !== undefined && ` Email verified: ${user.email_verified}`}
                                </span>
                            </div>
                            <button
                                className={styles.approveButton}
                                onClick={() => approveUser(user.id)}
                            >
                                Approve
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default UserRegistrationRequests;
